import {Message} from 'element-ui'

export default class Utils {
  message(msg, type = 'info') {
    // 消息提示 success/warning/info/error
    if (document.getElementsByClassName('el-message').length > 0) return

    Message({
      message: msg,
      center: true,
      duration: 2000,
      type
    })
  }

  getLastMonth() {
    //先获取当前日期
    let data = new Date()
    let year = data.getFullYear()
    //当前月份需要加一
    let mon = data.getMonth() + 1
    let day = data.getDate()
    //一月的时候年份要减一
    if (mon == 1) {
      year--
      mon = 12
    } else if (mon == 3 && day > 28) {
      //三月要考虑是否为闰年
      mon--
      if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
        day = 29
      } else {
        day = 28
      }
    } else if (mon != 12 || mon != 8 && day == 31) {
      //31号的月份要考虑上个月是否有31号
      mon--
      day == 30
    } else {
      mon--
    }
    //最后拼接年月日
    let date = year + "-" + mon + "-" + day
    return date
  }
}
